body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
}

#root {
  display: flex;
  align-self: stretch;
  flex-grow: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTableRow-head {
  border: "10px solid black";
}

.MuiMenu-list {
  flex-grow: 1;
}

::-webkit-scrollbar {
    height: 0.4em;
    width: 0.4em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

::-webkit-scrollbar-thumb {
    background: #43A047;
    border-radius: 2px;
}
